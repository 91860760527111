import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Menu.scss";

import { ReactComponent as MouseIcon } from "./assets/mouse.svg";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import Header from "../Header/Header";

const Menu = props => {
  const [currentSlide, setCurrentSlide] = useState(props.store.state.currentSlide);
  const [currentBtn, setCurrentBtn] = useState(props.store.state.currentSlide);
  const [isSliderShake, setSliderShake] = useState(false);
  const [currentSelectedSlide, setCurrentSelectedSlide] = useState(0);

  const refSlider = useRef(null);
  const refTimeline = useRef(null);
  useEffect(()=> {
    refTimeline.current.scrollLeft = currentSlide * currentSlide * 10
  },[])

  useEffect(() => {
    if (isSliderShake) {
      setTimeout(() => setSliderShake(false), 1000);
    }


    return () => clearInterval(window.intervalID);
  }, [isSliderShake]);

  

  const settings = {
    className: isSliderShake && "shake",
    infinite: false,
    easing: "ease-in",
    slidesToShow: 4.5,
    initialSlide: currentSlide,
    arrows: false,
    speed: 1000,
    dots: false,
    draggable: false,
    beforeChange: (curr, next) => {
      setCurrentSlide(next);
      refTimeline.current.scrollLeft = next * next * 10;
      setCurrentSelectedSlide(next);
      setCurrentBtn(next);
    }
  };

  return (
    <div className="menu">
      <div style={{ height: "4rem" }}>
        <Header  />
      </div>

      <div className="slider-container">
        <div
          className="slider-hover-left"
          onMouseEnter={() =>
            (window.intervalID = setInterval(
              () => refSlider.current.slickPrev(),
              10
            ))
          }
          onMouseLeave={() => clearInterval(window.intervalID)}
        />
        <Slider ref={refSlider} {...settings}>
          {props.store.state.site.map((el, i) => (
            <Link to={`/${el.name}`} key={el.name} onClick={() => props.store.actions.currentSlide(i)}>
              <div
                className={`menu__slide menu__slide__${el.name}`}
                style={
                  currentSelectedSlide === i ? { filter: "grayscale(0)" } : null
                }
                onMouseEnter={() => setCurrentSelectedSlide(i)}
                onMouseLeave={() => setCurrentSelectedSlide(currentSlide)}
              />
            </Link>
          ))}
        </Slider>
        <div
          className="slider-hover-right"
          onMouseEnter={() =>
            (window.intervalID = setInterval(() => {
              refSlider.current.slickNext();
            }, 10))
          }
          onMouseLeave={() => clearInterval(window.intervalID)}
        />
      </div>
      <div className="mouse-icon" >
        <ArrowIcon className="mouse-icon__left" />
        <MouseIcon className="mouse-icon__mouse" />
        <ArrowIcon className="mouse-icon__right" />
      </div>
      <div className="timeline-container">
        <div className="timeline-scroll" ref={refTimeline}>
          {props.store.state.site.map((site, i) => (
            <div className="timeline" key={`timeline_${i}`}>
              <button
                onClick={e => {
                  if (i <= 14) {
                    refTimeline.current.scrollLeft = i * i * 10;
                  }

                  i < 13
                    ? refSlider.current.slickGoTo(i)
                    : refSlider.current.slickGoTo(12);

                  i > 12 || currentSlide === i
                    ? setSliderShake(true)
                    : setSliderShake(false);

                  setCurrentBtn(i);
                  setCurrentSelectedSlide(i)
                }}
                className="timeline__btn"
                style={currentBtn === i ? { background: "#f25a4b" } : null}
              >
                {i + 1}
              </button>
              <div className="timeline__title">
                {site.title}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="copyright">
        <p className="copyright__text">
          Fotografie użyte w serwisie pochodzą ze zbiorów Narodowego Archiwum
          Cyfrowego i Wojskowego Biura Historycznego, zdjęcie partyzanta (sekcja
          Polskie Państwo Podziemne) pochodzi ze zbiorów Instytutu Pamięci
          Narodowej, zaś fotografia Jana Piwnika "Ponurego" autorstwa Feliksa
          Konderki "Jerzego" (sekcja umieszczamy dzięki uprzejmości
          p.&nbsp;Mariusza Baran-Barańskiego (sekcja "Cichociemni"). Zdjęcie
          Mikołaja Troickiego ("1 i 2 Armia WP") pochodzi ze zbiorów Muzeum
          Ziemi Sochaczewskiej i Pola Bitwy nad Bzurą. Fotografia Elżbiety
          Zawackiej pochodzi ze zbiorów Polskiej Agencji Prasowej.
        </p>
      </div>
    </div>
  );
};

export default Menu;
