import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const MenuMobile = props => {
  const { site } = props.store.state;

  const devidedSlides = (window.innerWidth * 0.8) / site.length;

  const [currentSlide, setCurrentSlide] = useState(props.store.state.currentSlide);
  const [positionDragX, setPositionDragX] = useState(0);
  const refSlider = useRef(null);
  const refIndicator = useRef(null);

  useEffect(() => {
    setPositionDragX(devidedSlides * props.store.state.currentSlide);
  },[])

  const settings = {
    dots: false,
    infinite: true,
    initialSlide: currentSlide,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (curr, next) => {
      setCurrentSlide(next);
      setPositionDragX(devidedSlides * next);
    }
  };
  return (
    <div className="menu menu-mobile">
      <h2 className="menu-mobile__header">
        Polacy na frontach II Wojny Światowej
      </h2>
      <div className="menu-mobile__slider">
        <button
          className="arrow-left"
          onClick={() => refSlider.current.slickPrev()}
        >
          {/* {"<"} */}
        </button>
        <Slider ref={refSlider} {...settings}>
          {site.map((el, i) => (
            <Link to={`/${el.name}`} onClick={() => props.store.actions.currentSlide(i)}>
              <div
                className={`menu__slide menu__slide__${el.name} ${
                  currentSlide === i ? "animate-slide" : ""
                }`}
                key={el.name}
              />
            </Link>
          ))}
        </Slider>
        <button
          className="arrow-right"
          onClick={() => refSlider.current.slickNext()}
        ></button>
      </div>
      <div className="menu-mobile__timeline timeline">
        <div
          className="timeline__base"
          onClick={e => {
            setPositionDragX(
              e.clientX - window.innerWidth * 0.1 - devidedSlides / 2
            );
            refSlider.current.slickGoTo(
              Math.floor( (e.clientX  - window.innerWidth * 0.1) / devidedSlides)
            );
            
           
          }}
        />
        <div
          className="timeline__indicator"
          ref={refIndicator}
          style={{ width: `${devidedSlides}px`, left: `${positionDragX}px` }}
          onTouchStart={() => (refIndicator.current.style.padding = "1.5%")}
          onTouchMoveCapture={e => {
            const max = window.innerWidth * 0.8;
            if (0 <= e.touches[0].clientX && max >= e.touches[0].clientX) {
              setPositionDragX(
                e.touches[0].clientX -
                  window.innerWidth * 0.1 -
                  devidedSlides / 2
              );
            }
          }}
          onTouchEnd={() => {
            refIndicator.current.style.padding = "0";
            refSlider.current.slickGoTo(
              Math.floor(positionDragX / devidedSlides)
            );
          }}
        />
      </div>
    </div>
  );
};

export default MenuMobile;
