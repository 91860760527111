import React from "react";
import { Link } from "react-router-dom";
import "./list.scss";
import {Consumer} from '../../../Store/context'
export default function List(props) {
  return (
    <>
    <div className="list">
        <ul>
          <Consumer>
            {store => (
              store.state.site.map((x, i) => (
              <li>
                
                <div className="list__element">
                <Link to={`/${x.name}`} onClick={() => store.actions.currentSlide(i)}>
                <span>{i+1}</span>
                <span>{x.name.split(/(?=[A-Z])/).join(" ")}</span>
                </Link>
              </div>
            </li>
           




        )) 
      )}
    </Consumer>
    </ul>
  </div>
  </>
  )}



   