import React, { useState } from "react";
import { Provider } from "./Store/context";

import Store from "./Store/store";

import PathRouter from "./PathRouter";

import "./App.css";

function App() {
  const [state, setState] = useState(Store);

  return (
    <div className="App">
      <Provider
        value={{
          state: state,
          actions: {
            togglePlaying: () => {
              return setState({ ...state, isPlaying: !state.isPlaying });
            },
            changeIndex: (a) => {
              return setState({ ...state, playingIndex: a });
            },
            currentSlide: (index) => setState({...state, currentSlide: index})
          }
        }}
      >
        <PathRouter />
      </Provider>
    </div>
  );
}

export default App;
