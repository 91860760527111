import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Consumer } from "../../Store/context";
import AudioPlayer from "../Audio/AudioPlayer";
import AudioPlayerMini from "../Audiomini/AudioPlayerMini";
import VideoPlayer from "../Wideo/Video";
import { Scrollbars } from "react-custom-scrollbars";
import Slider from "react-slick";
import Parser from "html-react-parser";
import MapMobile from "../MapMobile/MapMobile";
import "slick-carousel/slick/slick-theme.css";
import "./Info.scss";

const Info = (props) => {
  const sounds = props.data.sounds ? props.data.sounds : [];
  const playerItems = sounds.map((sound, index) => {
    return (
      <div>
        <AudioPlayer
          mp3Source={sound}
          key={sound.title.toString()}
          isPlayingToggle={props.store.actions.togglePlaying}
          changeIndex={props.store.actions.changeIndex}
          stillPlay={props.store.state.isPlaying}
          playingIndex={props.store.state.playingIndex}
          index={index}
        />
      </div>
    );
  });
  const prevSite = function () {
    let index = props.store.state.sites.indexOf(props.data.name);
    if (index - 1 >= 0) {
      let link = "/" + props.store.state.sites[index - 1];
      window.scrollTo(0, 0);
      props.history.push(link);
    } else {
      props.history.push("/");
    }
  };
  const nextSite = function () {
    let index = props.store.state.sites.indexOf(props.data.name);
    if (index + 1 < props.store.state.sites.length) {
      let link = "/" + props.store.state.sites[index + 1];
      window.scrollTo(0, 0);
      props.history.push(link);
    }
  };
  const isFirstSite = props.store.state.sites.indexOf(props.data.name) === 0;
  const isLastSite =
    props.store.state.sites.indexOf(props.data.name) + 1 >=
    props.store.state.sites.length;
  const goToMenu = function () {
    props.history.push("/menu");
  };
  const settings = {
    dots: true,
    className: "center",
    infinite: false,
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
    appendDots: (dots) => (
      <div
        style={{
          margin: "20px auto",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "20px",
          backgroundColor: "#b0a99d",
          height: "20px",
        }}
      ></div>
    ),
  };

  var general = (
    <>
      <div className="info__general-contaier">
        <div className="info__return-button">
          <p onClick={goToMenu}>Powrót</p>
        </div>
        <div
          className={`info__general-photo info__general-photo-${props.data.generalPhoto}`}
        >
          <div className="info__general-voice">
            <AudioPlayerMini
              mp3Source={props.data.generalVoice}
              changeIndex={props.store.actions.changeIndex}
              playingIndex={props.store.state.playingIndex}
            />
          </div>
        </div>
        <div className="info__general-details">
          <div className="info__general-name">{props.data.generalName}</div>
          <div className="info__general-title">{props.data.generalTitle}</div>
          <div className="info__general-data">
            {Parser(props.data.generalDescription)}
          </div>
        </div>
      </div>
    </>
  );

  var audioHtml = (
    <>
      <div className="info__sounds-container">
        <p className="info__sounds-container-description">Głosy świadków</p>
        <Slider {...settings}>{playerItems}</Slider>
      </div>
    </>
  );

  var buttonReturn = (
    <>
      <div className="info__return-button">
        <p onClick={goToMenu}>Powrót</p>
      </div>
    </>
  );
  return (
    <>
      <div className="info">
        {props.data.name === "Wrzesien39" ? <div></div> : general}
        <div className="info__main-description">
          {props.data.name === "Wrzesien39" ? buttonReturn : <div></div>}
          <div className="info__title-container">
            <div className="info__title-square" />
            <div className="info__title-data">
              <div className="info__title">{props.data.title}</div>
              <div className="info__date">{props.data.date}</div>
            </div>
          </div>
          <div className="info__scrollBar-container">
            <div className="info__content">{Parser(props.data.info)}</div>
          </div>
        </div>
        {props.data.name === "Wrzesien39" ? <MapMobile /> : audioHtml}
        <div className="info__video-container">
          <VideoPlayer movies={props.data.movies} />
          <div className="info__see-serwice-button">
            {!props.data.specialLink ? (
              <div></div>
            ) : (
              <a href={props.data.specialLink} target="_blank">
                <div className={props.data.specialBackgroundPhoto}></div>
              </a>
            )}
          </div>
          <div className="info__site-controls">
            {!isFirstSite && (
              <div className="" onClick={prevSite}>
                <p>Poprzedni rozdział</p>
              </div>
            )}
            {!isLastSite && (
              <div className="" onClick={nextSite}>
                <p>Następny rozdział</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Info);
