import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import './Main.scss';
import Flag from './assets/flaga.png';
import Ruins from './assets/ruins2.jpg';
import firstPlan from './assets/plan1.png';
import secondPlan from './assets/2plan.png';
import Logo from './assets/logo24.png';
import MainMobile from './mainMobile/MainMobile';
export default function Main() {

    const [position, setPosition] = useState({ x: 0, y: 0 })

    if (window.innerWidth < 768) {
        return (
            <>
                <MainMobile />
            </>
        )
    }
    else {
        return (
            <div className="main" onMouseMove={(e) => setPosition({ x: e.screenX, y: e.screenY })}>
                <div className="main__date">
                    <span>1.09.1939</span>
                    <img src={Flag}></img>
                    <span>8.05.1945</span>
                </div>
                <div className="main__header">
                    <h1>
                        polacy na frontach <br></br>
                        II wojny światowej
               </h1>
                    <div></div>
                    <p>1 września upłynie 80 lat od wybuchu II wojny światowej, największego konfliktu zbrojnego</p>
                    <p>w historii świata. Wszystkie Programy Polskiego Radia przygotowały specjalne audycje</p>
                    <p>upamiętniające tragiczne wydarzenia września 1939 roku.</p>
                    <Link to="/menu">
                        <span className="see-more__button">
                            zobacz więcej
                   </span>
                    </Link>
                </div>
                
                <img className="main__logo24" src={Logo}></img>
                <div className="main__first-plan" style={{ transform: `translate3d(-${position.x / 6000}rem, -${position.y / 6000}rem, 0)`, backgroundImage:`url(${firstPlan})`}}></div>
                <div className="main__second-plan"  style={{ transform:  `translate3d(-${position.x / 4000}rem, -${position.y / 4000}rem, 0)`, backgroundImage:`url(${secondPlan})`}}></div>
                <div className="main__ruins"  style={{ transform:  `translate3d(-${position.x / 2000}rem, -${position.y / 2000}rem, 0)`, backgroundImage:`url(${Ruins})` }}></div>
            </div>

        )

    }
}
