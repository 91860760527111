import React, { Component } from "react";
import Slider from "react-slick";
import './Video.scss';


class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movies: this.props.movies,
      currentSlide: 0
    };
  }



  render() {
    const videoPlayerItems = this.state.movies.map((movie, index) => {

        if(this.state.currentSlide === index){
            return (
              <div className="video__movie-container">
                <div className="video__movie-responsive-size">
                  <div className="video__movie-responsive">
                    <iframe src={movie.url} allowfullscreen=""></iframe>
                  </div>
                </div>
                <div className="video__movie-data">
                  <p>{movie.title}</p>
                  <div>{movie.description}</div>
                </div>
              </div>
            );
        } else {
          return <div></div>
        }
    });
    const videoPlayerItemsMobile = this.state.movies.map((movie, index) => {
      return (
          <div className="video__movie-container">
            <div className="video__movie-responsive-size">
              <div className="video__movie-responsive">
                <iframe src={movie.url} allowfullscreen=""></iframe>
              </div>
            </div>
            <div className="video__movie-data">
              <p>{movie.title}</p>
              <div>{movie.description}</div>
              </div>
            </div>
          );
    });
    const settings = {
        dots: true,
        arrows: false,
        className: "center",
        infinite: false,
        slidesToShow: 1,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        beforeChange: (prev, next) => this.setState(prevstate => ({currentSlide: next})),
        appendDots: dots => (
          <div
            style={{
              margin: "20px auto"
            }}
          >
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
        customPaging: i => (
          <div
            style={{
              width: "20px",
              backgroundColor: "#b0a99d",
              height: "20px"
            }}
          >

          </div>
        )
      };
    return (
      <Slider {...settings}>
        {window.innerWidth < 769 ? (videoPlayerItemsMobile) : (videoPlayerItems)}
      </Slider>
    );
  }
}

export default Video;
