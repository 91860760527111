import React from "react";
import { Link } from "react-router-dom"
import {Consumer} from '../../Store/context'
import AudioPlayer from "../Audio/AudioPlayer";
import AudioPlayerMini from "../Audiomini/AudioPlayerMini";
import VideoPlayer from "../Wideo/Video";
import { Scrollbars } from "react-custom-scrollbars";
import Slider from "react-slick";
import Parser from 'html-react-parser';
import Map from '../Map/Map'
import Header from '../Header/Header'
import "slick-carousel/slick/slick-theme.css";
import "./Info.scss";

const Info = props => {
  const sounds = props.data.sounds ? (props.data.sounds) : ([]);
  const playerItems =
      sounds.map((sound, index) => {
        return (
          <div>
            <AudioPlayer
              mp3Source={sound}
              key={sound.title.toString()}
              isPlayingToggle={props.store.actions.togglePlaying}
              changeIndex={props.store.actions.changeIndex}
              stillPlay={props.store.state.isPlaying}
              playingIndex={props.store.state.playingIndex}
              index={index}
            />
          </div>
        );
      });

  const settings = {
      dots: true,
      arrows: false,
      className: "center",
      infinite: false,
      slidesToShow: 1,
      speed: 500,
      rows: 2,
      slidesPerRow: 1,
      appendDots: dots => (
        <div
          style={{
            margin: "20px auto"
          }}
        >
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div
          style={{
            width: "20px",
            backgroundColor: "#b0a99d",
            height: "20px"
          }}
        >

        </div>
      )
    };

  var restOf = (<>
    <div className="info__general-contaier">
      <div
        className={`info__general-photo info__general-photo-${
          props.data.generalPhoto
        }`}
      >
        <div className="info__general-voice">
          <AudioPlayerMini
            mp3Source={props.data.generalVoice}
            changeIndex={props.store.actions.changeIndex}
            playingIndex={props.store.state.playingIndex}
          />
        </div>
      </div>
      <div className="info__general-details">
        <div className="info__general-name">{props.data.generalName}</div>
        <div className="info__general-title">
          {props.data.generalTitle}
        </div>
        <div className="info__general-data">
          {Parser(props.data.generalDescription)}
        </div>
      </div>
    </div>
    <div className="info__sounds-container">
      <p className="info__sounds-container-description">Głosy świadków</p>
        <Slider {...settings}>
          {playerItems}
        </Slider>
    </div>
    </>
  )

  return (
    <>
      <div className="info">
        <Header className="info__header" white/>
        <div
          className={`info__left-column info__left-photo-${
            props.data.backgroundLeftColumn
          }`}
        >
          <div className="info__return-button">
            <Link to="/menu"><p>Powrót</p></Link>
          </div>
          <div className="info__title-container">
            <div className="info__title-square" />
            <div className="info__title-data">
              <div className="info__title">{props.data.title}</div>
              <div className="info__date">{props.data.date}</div>
            </div>
          </div>
          <div className="info__scrollBar-container">
            <Scrollbars className="info__scrollBar">
              <div className="info__content">
                {Parser(props.data.info)}
              </div>
            </Scrollbars>
          </div>
          <div className="info__see-serwice-button">
            {!props.data.specialLink ? (<div></div>) : (
              <a href={props.data.specialLink} target="_blank">
                <div className={props.data.specialBackgroundPhoto}>
                </div>
              </a>
            )}
          </div>
        </div>
        <div
          className={`info__right-column info__right-photo-${
            props.data.backgroundRightColumn
          }`}
        >
          {props.data.name === "Wrzesien39" ? (<Map/>) : (restOf)}
          <div className="info__video-container">
            <VideoPlayer movies={props.data.movies}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
