import React, { Component } from "react";
import "./AudioPlayer.scss";
import { ReactComponent as Speaker } from "./icons/speaker.svg";
import { ReactComponent as Pausa } from "./icons/pausa.svg";
import Parser from "html-react-parser";

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      currentTime: 0,
      duration: 0,
    };
    this.player = React.createRef();
    this.timelineRef = React.createRef();
    this.disactiveTimelineRef = React.createRef();
    this.startPlay = this.startPlay.bind(this);
    this.timeline = this.timeline.bind(this);
    this.timelineAct = this.timelineAct.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.playingIndex !== this.props.playingIndex) {
      if (this.props.index !== this.props.playingIndex) {
        this.player.current.pause();
        this.setState({ isPlaying: false });
      } else {
        this.player.current.play();
        this.setState({ isPlaying: true });
      }
    }
  }

  startPlay() {
    if (this.props.index === this.props.playingIndex && this.state.isPlaying) {
      this.player.current.pause();
      this.setState({ isPlaying: false });
    } else if (
      this.props.index === this.props.playingIndex &&
      !this.state.isPlaying
    ) {
      this.player.current.play();
      this.setState({ isPlaying: true });
    } else {
      this.props.changeIndex(this.props.index);
    }
  }

  timeline() {
    this.setState({
      currentTime: this.player.current.currentTime,
      duration: this.player.current.duration,
    });
  }

  timelineAct(e) {
    let wholeSong = this.player.current.duration;
    let coords = this.disactiveTimelineRef.current.getBoundingClientRect();
    let clickCoords = e.clientX - coords.left;
    let songActualTime = (clickCoords / coords.width) * wholeSong;
    this.setState({ currentTime: songActualTime });
    this.player.current.currentTime = songActualTime;
  }

  render() {
    let button;
    if (!this.state.isPlaying) {
      button = (
        <div className="player__button-container" onClick={this.startPlay}>
          <div className="icon-container">
            <Speaker />
          </div>
        </div>
      );
    } else {
      button = (
        <div
          className="player__button-container--orange"
          onClick={this.startPlay}
        >
          <div className="icon-container">
            <Pausa className="player__button-pause" />
          </div>
        </div>
      );
    }

    return (
      <div className="player">
        <div className="player__container">
          {button}
          <div className="player__timeline-and-text">
            <div className="player__timeline-title">
              {this.props.mp3Source.title}
            </div>
            <div className="player__timeline-description">
              {Parser(this.props.mp3Source.description)}
            </div>
            <div
              className="player__timeline"
              ref={this.disactiveTimelineRef}
              onClick={this.timelineAct}
            >
              <div className="player_target-timeline"></div>
              <div
                className="player__timeline-active"
                style={{
                  width: this.state.currentTime
                    ? (this.state.currentTime / this.state.duration) * 100 + "%"
                    : "0%",
                }}
                ref={this.timelineRef}
              ></div>
            </div>
          </div>
          <audio ref={this.player} onTimeUpdate={this.timeline}>
            <source src={this.props.mp3Source.url} />
          </audio>
        </div>
      </div>
    );
  }
}

export default AudioPlayer;
