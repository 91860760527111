import React, { Component } from "react";
import "./AudioPlayerMini.scss";
import { ReactComponent as Speaker } from "./icons/speaker.svg";
import { ReactComponent as Pausa } from "./icons/pausa.svg";

class AudioPlayerMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      index: -1,
    };
    this.player = React.createRef();
    this.startPlay = this.startPlay.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.playingIndex !== this.props.playingIndex) {
      if (this.props.index !== this.props.playingIndex) {
        this.player.current.pause();
        this.setState({ isPlaying: false });
      } else {
        this.player.current.play();
        this.setState({ isPlaying: true });
      }
    }
  }

  startPlay() {
    if (this.props.index === this.props.playingIndex && this.state.isPlaying) {
      this.player.current.pause();
      this.setState({ isPlaying: false });
    } else if (
      this.props.index === this.props.playingIndex &&
      !this.state.isPlaying
    ) {
      this.player.current.play();
      this.setState({ isPlaying: true });
    } else {
      this.props.changeIndex(this.props.index);
    }
  }

  render() {
    let button;
    if (!this.state.isPlaying) {
      button = (
        <div className="player-mini__container" onClick={this.startPlay}>
          <div className="player-mini__button-container">
            <Speaker />
          </div>
          <audio ref={this.player}>
            <source src={this.props.mp3Source} />
          </audio>
        </div>
      );
    } else {
      button = (
        <div className="player-mini__container" onClick={this.startPlay}>
          <div className="player-mini__button-container--orange">
            <Pausa className="player-mini__button-pause" />
          </div>
          <audio ref={this.player}>
            <source src={this.props.mp3Source} />
          </audio>
        </div>
      );
    }

    return <div className="player-mini">{button}</div>;
  }
}

export default AudioPlayerMini;
