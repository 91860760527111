import React, { Component } from "react";
import Parser from 'html-react-parser';
import './Map.scss';


class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopUp: false,
      popUpInfo: {
        "title": "",
        "description": "",
        "image": "",
        "video": ""
      },
      info: [
    		{
    			"title": "Na straconej pozycji",
    			"description": "Hel bronił się najdłużej. Rejon Umocniony Hel skapitulował, mimo miażdżącej przewagi przeciwnika, dopiero 2 października. Posłuchaj wspomnień admirała Józefa Unruga, dowódcy polskiej Marynarki Wojennej we wrześniu 1939 roku.",
          "image": "",
          "video": "//embed.polskieradio.pl/video/0d81b221-c815-4ac2-b7a6-1e209ce8c70d"
    		},
    		{
    			"title": "Westerplatte -zamiast 12 godzin walczyli 7 dni",
    			"description": "O godzinie 4:30 rano 1 września 1939 roku niemiecki pancernik Schleswig-Holstein odcumował od nabrzeża i ruszył w stronę zakola kanału portowego, zwanego Zakrętem Pięciu Gwizdków. Powoli zaczął zbliżać się do Westerplatte. W kwadrans później działa okrętu otworzyły ogień i do szturmu na Wojskową Składnicę Tranzytową ruszyło ponad 3 tysiące niemieckich żołnierzy z morza, lądu i powietrza. Zaczęła się II wojna światowa.",
    			"image": "",
    			"video": "//embed.polskieradio.pl/video/1860dc6e-d323-4bca-8884-9de03036af19"
    		},
    		{
    			"title": "O 4.43 bomby spadły na Tczew...",
    			"description": "Celem Luftwaffe było zniszczenie przewodów, które prowadziły do ładunków wybuchowych pod mostem. Wcześniej przeprawa na Wiśle o strategicznym znaczeniu miała być zajęta przez niemieckich dywersantów. Dzięki bohaterstwu zwykłych ludzi, pomorskich kolejarzy, Niemcy nie osiągnęli celu. Zgładzeni w odwecie razem z rodzinami Polacy stali się jednymi z pierwszych ofiar zbrodni niemieckiej okupacji.",
    			"image": "",
    			"video": "//embed.polskieradio.pl/video/d8ce6498-8335-46da-93d5-3bd13ecf0679"
    		},
    		{
    			"title": "Warszawa - &quot;A więc wojna!&quot;",
    			"description": "1 września 1939 o 6.30 dyżurny spiker Warszawy I Zbigniew Świętochowski zerwał plombę w szafie, w której znajdowała się przygotowana specjalnie na tę okoliczność płyta. Rozbrzmiało wycie syren, a następnie słuchacze usłyszeli komunikat o wkroczeniu wojsk niemieckich do Polski.<br><br>5 września padł rozkaz ewakuacji Polskiego Radia. Mimo to grupa ochotników pod wodzą Edmunda Rudnickiego podjęła heroiczną decyzję o pozostaniu w walczącym mieście.",
    			"image": "",
    			"video": "//embed.polskieradio.pl/video/9aba7fcd-e73e-4185-9a0b-0abdcd53d0d6"
    		},
    		{
    			"title": "Wieluń - polska Guernica",
          "description": "Tutaj eksplodowały pierwsze bomby II wojny światowej. Tutaj padły pierwsze serie z karabinów maszynowych. 1 września 1939 o godz. 4.40 śpiące miasto zaatakowało czternaście samolotów z czarnymi krzyżami. O walkach w rejonie Wielunia mówi świadek tamtych wydarzeń, uczestnik walk, Mieczysław Matyjaszek.",
    			"image": "",
    			"video": "//embed.polskieradio.pl/video/616fc61b-6c64-46cd-ac6c-baa8a8f6d2e3"
    		},
    		{
    			"title": "Gliwice - niemiecki pretekst do napadu na Polskę",
    			"description": "31 sierpnia 1939 do budynku radiostacji w niemieckich wówczas Gliwicach wtargnęło kilku uzbrojonych esesmanów w ubraniach cywilnych. Napastnicy sterroryzowali niemiecką załogę i nadali po polsku komunikat: &quot;Uwaga! Tu Gliwice. Radiostacja znajduje się w polskich rękach...&quot;. Zamordowano wówczas Franciszka Honioka, polskiego Ślązaka, uważanego obecnie za pierwszą ofiarę II wojny światowej.",
    			"image": "//static.prsa.pl/fc4ed856-0832-4ee2-9db6-1d96b6a5c4e7.file",
    			"video": ""
    		},
    		{
    			"title": "Moskwa - relacja ambasadora",
    			"description": "W nocy 17 września 1939 roku Wacław Grzybowski, ambasador Polski w ZSRR, został wezwany pilnie do Komisariatu Spraw Zagranicznych ZSRR. Tam zastępca Wiaczesława Mołotowa, Władimir Potiomkin, przekazał mu notę o wejściu wojsk sowieckich na terytorium II RP.",
    			"image": "",
    			"video": "//embed.polskieradio.pl/video/ddb385d3-d097-4561-98b0-81695b834449"
    		},
    		{
    			"title": "Baranowicze - rozgłośnia na wschodzie",
    			"description": "Po wtargnięciu do Polski, Sowieci aresztowali przedstawicieli państwa polskiego i lokalną elitę- urzędników, policjantów, oficerów, księży. Również pracownikom Polskiego Radia groziło aresztowanie. Wspominał o tym spiker rozgłośni w Baranowiczach, Stefan Sojecki.",
    			"image": "",
    			"video": "//embed.polskieradio.pl/video/74c49389-b2ff-4f74-890d-15c9e809cc17"
    		},
    		{
    			"title": "Lwów - stąd wywożono do Katynia",
    			"description": "Lwów kapitulował przed Armią Czerwoną 23 września 1939. W akcie kapitulacji zagwarantowano bezpieczeństwo dla polskich żołnierzy i policjantów po złożeniu broni. Sowieci nie dotrzymali obietnicy i aresztowali oficerów, z których wielu zamordowano później w Katyniu. Miasto wcielono do Ukraińskiej Socjalistycznej Republiki Radzieckiej. Wiadomość o wtargnięciu Rosjan do Polski była szokiem dla cywilnej ludności miasta, co wspomina Hanna Rudzińska, przedwojenna mieszkanka Lwowa.",
    			"image": "",
    			"video": "//embed.polskieradio.pl/video/a4aaf9fb-7bd0-4491-9758-5613dfb0ec26"
    		},
    		{
    			"title": "Wilno - ucieczka przez Sowietami",
    			"description": "Wilno wpadło w ręce Armii Czerwonej 19 września 1939 po dwudniowej, heroicznej obronie, w której udział brała cywilna ludność miasta. 26 października Rosjanie przekazali Wilno Litwinom. Po aneksji Litwy przez ZSRR w 1940 roku, Wilno znalazło się ponownie pod radziecką okupacją. Polska ludność Wileńszczyzny była poddana sowieckiemu terrorowi i wysiedlana. Irena Baranowska-Tyman wspomina popłoch, jaki zapanował wśród polskich żołnierzy na wieść o zbliżających się wojskach rosyjskich.",
    			"image": "",
    			"video": "//embed.polskieradio.pl/video/7284c549-4b89-42fa-8afb-4fd88b9f8dac"
    		}
    	]
    };
    this.closePopUp = this.closePopUp.bind(this);
    this.doNothing = this.doNothing.bind(this);
    this.openPopUp = this.openPopUp.bind(this);
  }

  closePopUp(event) {
    event.stopPropagation()
    this.setState({showPopUp:false});
  }
  doNothing(event) {
    event.stopPropagation()
  }
  openPopUp(index) {
    this.setState({
      popUpInfo: this.state.info[index],
      showPopUp: true
    });
  }

  render() {
    let popup;
    if(this.state.showPopUp) {
      popup = (
        <>
        <div className="Map1939__map-pop-up" onClick={this.closePopUp}>
          <div className="Map1939__map-pop-up-content">
            <div className="Map1939__close" onClick={this.closePopUp}>
              X
            </div>
            <div
              className="Map1939__info"
              onClick={this.doNothing}
            >

                  {this.state.popUpInfo.image === "" ? (
                    <div className="Map1939__movie-container">
                      <div className="Map1939__movie">
                        <iframe src={this.state.popUpInfo.video}/>
                      </div>
                    </div>
                  ) :
                  <img src={this.state.popUpInfo.image}/>}
              <div className="Map1939__text">
                <h2>{Parser(this.state.popUpInfo.title)}</h2>
                <p>{Parser(this.state.popUpInfo.description)}</p>
              </div>
            </div>
          </div>
        </div>
        </>
      )
    }
    return (
      <div className="Map1939">
        <div className="Map1939__map-container">
          <div
            className="Map1939__point Map1939__point--poland Map1939__point--hel"
            onClick={()=>this.openPopUp(0)}
          >
            <p>Hel</p>
          </div>
          <div
            className="Map1939__point Map1939__point--poland Map1939__point--westerplatte"
            onClick={()=>this.openPopUp(1)}
          >
            <p>Westerplatte</p>
          </div>
          <div
            className="Map1939__point Map1939__point--poland Map1939__point--tczew"
            onClick={()=>this.openPopUp(2)}
          >
            <p>Tczew</p>
          </div>
          <div
            className="Map1939__point Map1939__point--poland Map1939__point--warszawa"
            onClick={()=>this.openPopUp(3)}
          >
            <p>Warszawa</p>
          </div>
          <div
            className="Map1939__point Map1939__point--poland Map1939__point--wielun"
            onClick={()=>this.openPopUp(4)}
          >
            <p>Wieluń</p>
          </div>
          <div
            className="Map1939__point Map1939__point--poland Map1939__point--gliwice"
            onClick={()=>this.openPopUp(5)}
          >
            <p>Gliwice</p>
          </div>
          <div
            className="Map1939__point Map1939__point--ukraine Map1939__point--moskwa"
            onClick={()=>this.openPopUp(6)}
          >
            <p>Moskwa</p>
          </div>
          <div
            className="Map1939__point Map1939__point--ukraine Map1939__point--wilno"
            onClick={()=>this.openPopUp(9)}
          >
            <p>Wilno</p>
          </div>
          <div
            className="Map1939__point Map1939__point--ukraine Map1939__point--baranowicze"
            onClick={()=>this.openPopUp(7)}
          >
            <p>Baranowicze</p>
          </div>
          <div
            className="Map1939__point Map1939__point--ukraine Map1939__point--lwow"
            onClick={()=>this.openPopUp(8)}
          >
            <p>Lwów</p>
          </div>
        </div>
        {popup}
        <div className="Map1939__legend">
          <div className="Map1939__area">
            <div className="Map1939__area-poland">
              <div className="Map1939__area-poland-border">
                <div className="Map1939__area-poland-inside">
                </div>
              </div>
              - Granice III RP
            </div>
            <p className="Map1939__area-poland2">
              <div className="Map1939__area-poland-border">
                <div className="Map1939__area-poland2-inside">
                </div>
              </div>
              - Granice II RP
            </p>
          </div>
          <div className="Map1939__cities">
            <div className="Map1939__cities-poland">
              <div className="Map1939__cities-poland-border Map1939__cities-poland-inside">
              </div>
              - wybrane punkty zajęte przez Niemców
            </div>
            <div className="Map1939__cities-german">
              <div className="Map1939__cities-poland-border Map1939__cities-poland2-inside">
              </div>
              - wybrane punkty zajęte przez Sowietów
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Map;
