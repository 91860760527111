import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { Consumer } from "./Store/context";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Main from "./Components/Main/Main";
import Info from "./Components/Info/Info";
import Menu from "./Components/Menu/Menu";

//mobile

import MenuMobile from "./Components/Menu/MenuMobile";
import InfoMobile from "./Components/Info/InfoMobile";

const NotFound = () => {
  return <h1>404 Not found</h1>;
};

const Group = withRouter(({ location }) => {
  const [isMobile, setMobile] = useState(false);
  const [isTablet, setTablet] = useState(false)

  useEffect(() => {
    window.innerWidth < 767 ? setMobile(true) : setMobile(false);
    window.innerWidth < 1024 ? setTablet(true) : setTablet(false)
  }, []);

  return (
    <Consumer>
      {store => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={800} classNames='my-node'>
              <Switch location={location}>
                <Route exact path="/" component={Main} />
                <Route
                  path="/menu"
                  classNames="my-node-enter"
                  render={() =>
                    isTablet ? (
                      <MenuMobile store={store} />
                    ) : (
                      <Menu store={store} />
                    )
                  }
                />
                {store.state.site.map(el => (
                  <Route
                    path={`/${el.name}`}
                    key={el.name}
                    render={() =>
                      isMobile ? (
                        <InfoMobile data={el} store={store} />
                      ) : (
                        <Info data={el} store={store} />
                      )
                    }
                  />
                ))}
                <Route component={NotFound} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
      )}
    </Consumer>
  );
})


const PathRouter = () => (
  <BrowserRouter>
    <Group/>
  </BrowserRouter>
)

export default PathRouter
