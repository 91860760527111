import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "./assets/logo.svg";
import { ReactComponent as Home } from "./assets/home.svg";
import { ReactComponent as Burger } from "./assets/burger.svg";

import "./Header.scss";

const Header = props => {
  return (
    <div className={`header ${props.white && "white"}`}>
      <a href="https://polskieradio24.pl">
        <Logo className="header__logo" style={{fill: props.color}}/>
      </a>
      <div className="header__text">
        <span>
          Polacy na frontach
          <br />
          II Wojny Światowej
        </span>
      </div>
      <div className="header__icons">
        <Link to="/">
          <Home className="header__home" style={{fill: props.color}}/>
        </Link>
        <Link to="/menu">
          <Burger className="header__burger" style={{fill: props.color}}/>
        </Link>
      </div>
    </div>
  );
};

export default Header;
