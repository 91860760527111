import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Consumer } from '../../../Store/context'
import './mainMobile.scss';
import Flag from '../assets/flag.jpg';
import Burger from '../assets/burger.png';
import Off from '../assets/off.png';


import '../Main.scss';
import List from './List';

export default function MainMobile() {
    const [isVisible, setVisible] = useState(false);
    return (
        <div className="main main-mobile" >

            <div className={`${ isVisible ? "switch-button--off" : "switch-button" }`} onClick={() => setVisible(!isVisible)}>
                {!isVisible ? <img src={Burger} ></img> : <div><img src={Off}></img></div>}
            </div>

                <div className={!isVisible? "animate__main--mobile":"animate__main--mobile hidden"}>
                    <div className="main__date">
                        <span>1.09.1939</span>
                        <img src={Flag}></img>
                        <span>8.05.1945</span>
                    </div>

                    <div className="main__header">
                        <h1>
                            polacy na frontach <br></br>
                            II wojny światowej
                    </h1>
                        <div></div>
                        <p>1 września upłynie 80 lat od wybuchu II wojny światowej,<br></br>
                            największego konfliktu zbrojnego w historii świata.<br></br>
                            Wszystkie Programy Polskiego Radia przygotowały<br></br>
                            specjalne audycje upamiętniające tragiczne wydarzenia<br></br>
                            września 1939 roku.</p>
                        <Link to="/menu">
                            <span className="see-more__button">
                                zobacz dalej
                    </span>
                        </Link>


                    </div>
                </div>
                
                <div className={isVisible? 'list__container menuAnimate active' : 'list__container menuAnimate'}>
                <Consumer>
                    {store => <List isVisible={isVisible} store={store}/>}
                </Consumer>
                </div>
                <div className="mask" style={{opacity:!isVisible? '0':'0.4'}}></div>





        </div>
    )

}
